import { Box, Button, IconButton, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styled from '@emotion/styled';
import { shades } from '../theme';
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
} from '../state';
import { useNavigate } from 'react-router-dom';

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;  
`;

const CartMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const isCartOpen = useSelector((state) => state.cart.isCartOpen);

  const totalPrice = cart.reduce((total, product) => {
    return total + product.count * product.attributes.price;
  }, 0);

  return (
    <Box
      display={isCartOpen ? "block" : 'none'}
      backgroundColor="rgba(0, 0, 0, 0.4)"
      position="fixed"
      zIndex={10}
      width="100%"
      height="100%"
      left="0"
      top="0"
      overflow="auto"
    >
      {/* MODAL */}
      <Box
        position="fixed"
        right="0"
        bottom="0"
        width="max(400px, 30%)"
        height="100%"
        backgroundColor="white"
      >
        <Box padding="30px" overflow="auto" height="100%">
  
          {/* HEADER */}
          <FlexBox mb="15px">
            <Typography variant='h3'>SHOPPING BAG ({cart.length})</Typography>
            <IconButton onClick={() => dispatch(setIsCartOpen({}))}>
              <CloseIcon />
            </IconButton>
          </FlexBox>
  
          {/* CART List */}
          <Box>
            {cart.map((product) => (
              <Box key={`${product.attributes.name}-${product.id}`}>
                <FlexBox p="15px 0">
                  <Box flex="1 1 40%">
                    <img
                      alt={product?.name}
                      width="123px"
                      height="164px"
                      src={`${product?.attributes?.image?.data[0]?.attributes?.url}`}
                    />
                  </Box>
                  <Box flex="1 1 60%">
                    <FlexBox mb="5px">
                      <Typography fontWeight="bold">
                        {product.attributes.name}
                      </Typography>
                      <IconButton onClick={() => dispatch(removeFromCart({ id: product.id}))}>
                        <CloseIcon />
                      </IconButton>
                    </FlexBox>
                    <Typography>{product.attributes.shortDescription[0].children[0].text}</Typography>
  
                    {/* AMOUNT */}
                    <FlexBox m="15px 0">
                      <Box
                        display="flex"
                        alignItems="center"
                        border={`1.5px solid ${shades.neutral[500]}`}
                      >
                        <IconButton
                          onClick={() => dispatch(decreaseCount({ id: product.id}))}
                        >
                          <RemoveIcon />
                        </IconButton>
                        <Typography>{product.count}</Typography>
                        <IconButton
                          onClick={() => dispatch(increaseCount({ id: product.id}))}
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                      {/* PRICE */}
                      <Typography fontWeight="bold">
                        ${product.attributes.price}
                      </Typography>
                    </FlexBox>
                  </Box>
                </FlexBox>
              </Box>
            ))}
          </Box>
  
          {/* ACTIONS */}
          <Box m="20px 0">
            <FlexBox m="20px 0">
              <Typography fontWeight="bold">SUBTOTAL</Typography>
              <Typography fontWeight="bold">${totalPrice}</Typography>
            </FlexBox>
            <Button
              sx={{
                backgroundColor: shades.primary[400],
                color: "white",
                borderRadius: 0,
                minWidth: "100%", 
                padding: "20px 40px",
                m: "20px 0"
              }}
              onClick={() => {
                navigate("/checkout")
                dispatch(setIsCartOpen({}))
              }}
            >CHECKOUT</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )

};

export default CartMenu;