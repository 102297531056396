import ShoppingList from "./ShoppingList";

const Products = () => {
  return (
    <div className="products">
      <ShoppingList />
    </div>
  );
};

export default Products;